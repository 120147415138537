import Quill from "quill";

const Module = Quill.import("core/module");
const BlockEmbed = Quill.import("blots/block/embed");
const ATTRIBUTES = ["alt", "height", "width"];
export class ImageBlot extends BlockEmbed {
  static blotName = "image";
  static tagName = ["figcaption", "image"];
  static create(value) {
    let node = super.create();
    let img = window.document.createElement("img");
    if (value.alt || value.caption) {
      img.setAttribute("alt", value.alt || value.caption);
    }
    if (value.src || typeof value === "string") {
      img.setAttribute("src", value.src || value);
    }
    node.appendChild(img);

    let caption = window.document.createElement("figcaption");
    if (value.caption) {
      caption.innerHTML = value.caption;
      let captionParent = window.document.createElement("div");
      captionParent.className = "figcaptionparent";
      captionParent.appendChild(caption);
      node.appendChild(captionParent);
    }
    node.className = "ql-image-block";

    node.style.display = "inline-block";
    node.style.margin = "10px";
    return node;
  }
  static formats(domNode) {
    let img = domNode.querySelector("img");
    let figcaption = domNode.querySelector("figcaption");
    console.log(figcaption);
    const style = img?.style;
    if (figcaption) {
      figcaption.style.display = style?.display;
      figcaption.style.margin = style?.margin;
      let widths = img.width;
      figcaption.style.width = widths + "px";
      figcaption.style.float = style.float;
      figcaption.style.margin = "20px"
    }
    return ATTRIBUTES.reduce(function (formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  constructor(node) {
    super(node);
    node.__onSelect = () => {
      if (!node.querySelector("input")) {
        let caption = node.querySelector("figcaption");
        let captionInput = window.document.createElement("input");
        captionInput.placeholder = "Type your caption...";
        if (caption) {
          captionInput.value = caption.innerText;
          caption.innerHTML = "";
          caption.appendChild(captionInput);
        } else {
          caption = window.document.createElement("figcaption");
          caption.appendChild(captionInput);
          node.appendChild(caption);
        }
        captionInput.addEventListener("blur", () => {
          let value = captionInput.value;
          if (!value || value === "") {
            caption.remove();
          } else {
            captionInput.remove();
            caption.innerText = value;
          }
        });
        captionInput.focus();
      }
    };
    node.addEventListener("mousedown", () => {
      this.selectText(node);
    });
  }
  selectText(node) {
    let range, selection;
    if (document.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(node);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();
      range = document.createRange();
      range.selectNode(node);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
  static value(node) {
    let img = node.querySelector("img");
    let figcaption = node.querySelector("figcaption");
    if (!img) return false;
    return {
      alt: img.getAttribute("alt"),
      src: img.getAttribute("src"),
      caption: figcaption ? figcaption.innerText : "",
    };
  }
}
export class CardEditableModule extends Module {
  constructor(quill, options) {
    super(quill, options);
    let listener = (e) => {
      if (!document.body.contains(quill.root)) {
        return document.body.removeEventListener("click", listener);
      }
      let img = e.target.closest(".ql-image-block img");
      if (img) {
        let elm = img.closest(".ql-image-block");
        let deselectCard = () => {
          if (elm?.__onDeselect) {
            elm.__onDeselect(quill);
          } else {
            let blot = elm?.__blot?.blot;
            if (blot) {
              let blotIndex = quill.getIndex(blot);
              quill.setSelection(blotIndex + 1, 0, Quill.sources.USER);
            }
          }
        };
        if (elm && elm.__blot && elm.__onSelect) {
          quill.disable();
          elm.__onSelect(quill);
          let handleKeyPress = (e) => {
            if (e.keyCode === 27 || e.keyCode === 13) {
              window.removeEventListener("keypress", handleKeyPress);
              quill.enable(true);
              deselectCard();
            }
          };
          let handleClick = (e) => {
            if (e?.which === 1 && !elm.contains(e.target)) {
              window.removeEventListener("click", handleClick);
              quill.enable(true);
              deselectCard();
            }
          };
          window.addEventListener("keypress", handleKeyPress);
          window.addEventListener("click", handleClick);
        }
      }
    };
    quill.emitter.listenDOM("click", document.body, listener);
  }
}

Quill.register("modules/cardEditable", CardEditableModule);
Quill.register("formats/image", ImageBlot);
